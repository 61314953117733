import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Flex, Input, Text } from '@chakra-ui/react';
import { AiFillHeart } from 'react-icons/ai';

interface DataItem {
  id: string;
  EmpresaNome: string;
  FilialCnpj: string;
  ClienteOrigemCnpjCpf: string;
  ClienteOrigemNome: string;
  ClienteDestinoCnpjCpf: string;
  ClienteDestinoNome: string;
  ValorTotal: string;
}

function SearchResults() {
  const [data, setData] = useState<DataItem[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchEmpresa, setSearchEmpresa] = useState('');
  const [searchOrigem, setSearchOrigem] = useState('');
  const [searchDestino, setSearchDestino] = useState('');
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios.get<DataItem[]>(
        `http://localhost:3333/search?page=${page}&pageSize=${pageSize}&empresa=${searchEmpresa}&origem=${searchOrigem}&destino=${searchDestino}`,
      );

      console.log(result)

      setData(result.data);
      setIsLoading(false);
    };

    fetchData();
  }, [page, pageSize, searchEmpresa, searchOrigem, searchDestino]);

  useEffect(() => {
    setPage(1); // Define a página como 1 sempre que ocorrer uma alteração nos campos de input
  }, [searchEmpresa, searchOrigem, searchDestino]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding={4}>
      <Text color="blue.500" fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
        TUIRE TRANSPORTES
      </Text>
      <Box width="100%" mb={4}>
        <Input placeholder="Transportadora" value={searchEmpresa} onChange={event => setSearchEmpresa(event.target.value)} />
        <Input placeholder="Origem" value={searchOrigem} onChange={event => setSearchOrigem(event.target.value)} />
        <Input placeholder="Destino" value={searchDestino} onChange={event => setSearchDestino(event.target.value)} />
      </Box>
      <Box width="100%" overflowX="auto">
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Transportadora</Th>
              <Th>Origem (CNPJ)</Th>
              <Th>Destino (CNPJ)</Th>
              <Th>Origem</Th>
              <Th>Destino</Th>
              <Th>Valor Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(item => (
              <Tr key={item.id}>
                <Td>{item.EmpresaNome}</Td>
                <Td>{item.ClienteOrigemCnpjCpf}</Td>
                <Td>{item.ClienteDestinoCnpjCpf}</Td>
                <Td>{item.ClienteOrigemNome}</Td>
                <Td>{item.ClienteDestinoNome}</Td>
                <Td>{item.ValorTotal}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="space-between" width="100%" marginTop={4}>
        {page > 1 && (
          <Button colorScheme="blue" onClick={() => setPage(page - 1)} disabled={isLoading}>
            Anterior
          </Button>
        )}
        <Button colorScheme="blue" onClick={() => setPage(page + 1)} disabled={isLoading} ml="auto">
          Próximo
        </Button>
      </Flex>
      <Flex justify="center" align="center" mt="auto">
        <AiFillHeart color="red" size={20} />
        <Text mr={2}>Feito por Guilherme</Text>
        <AiFillHeart color="red" size={20} />
      </Flex>
    </Box>
    
  );
}
export default SearchResults;
