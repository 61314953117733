import React from 'react';
import './App.css';
import SearchResults from './SearchResults';

function App() {
  return (
    <div className="App">
      <SearchResults />
    </div>
  );
}

export default App;
