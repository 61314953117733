import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Chakra } from "./chakra";

ReactDOM.render(
  <Chakra>
    <App />
  </Chakra>,
  document.getElementById("root")
);
